import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { AuthContext } from '../context/AuthContext';

const ReportDetail = () => {
  const [reportDetail, setReportDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token, logout } = useContext(AuthContext);
  const { reportId } = useParams();
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    const fetchReportDetail = async () => {
      try {
        const response = await AuthService.fetchReportDetail(token, reportId);
        const decodedHtmlContent = decodeURIComponent(escape(atob(response.data.htmlContent))); // Decode base64 content
        setReportDetail({ ...response.data, htmlContent: decodedHtmlContent });
      } catch (error) {
        console.error('Error fetching report detail:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReportDetail();
  }, [token, reportId]);

  useEffect(() => {
    if (reportDetail && iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(reportDetail.htmlContent);
      iframeDoc.close();
    }
  }, [reportDetail]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!reportDetail) {
    return <div>Report detail not found.</div>;
  }

  return (
    <div>
      <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <button
          onClick={() => navigate(-1)}
          className="text-white bg-gray-700 hover:bg-gray-600 px-3 py-1 rounded"
        >
          Back
        </button>
        <h1 className="text-xl font-bold">Report Detail</h1>
        <button
          onClick={() => {
            logout();
            navigate('/');
          }}
          className="text-white bg-red-600 hover:bg-red-500 px-3 py-1 rounded"
        >
          Logout
        </button>
      </nav>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: 'calc(100vh - 64px)', border: 'none' }}
        title="Report Detail"
      />
    </div>
  );
};

export default ReportDetail;
