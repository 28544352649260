import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import AuthService from '../services/AuthService';
import { AuthContext } from '../context/AuthContext';
import { Base64 } from 'js-base64';

const ReportList = () => {
  const [user, setUser] = useState(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await AuthService.fetchUserProfile(token);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchReports = async () => {
      try {
        const response = await AuthService.fetchReports(token);
        setReports(response.data); // Assuming response.data is an array of reports
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
    fetchReports();
  }, [token]);

  useEffect(() => {
    if (user && user.client && user.client.dashboardHtml && iframeRef.current) {
      try {
        const decodedHtmlContent = Base64.decode(user.client.dashboardHtml); // Decode base64 content
        const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(decodedHtmlContent);
        iframeDoc.close();
      } catch (error) {
        console.error('Error decoding and displaying dashboard content:', error);
      }
    }
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleReportClick = (reportId) => {
    navigate(`/reports/${reportId}`);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MM-yyyy');
  };

  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Dashboard</h1>
        <button
          onClick={handleLogout}
          className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Logout
        </button>
      </nav>
      <div className="flex flex-grow overflow-hidden">
        <div className="flex-grow">
          <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Dashboard Content"
          />
        </div>
        <div className="w-1/4 bg-white p-6 overflow-y-auto border-l border-gray-200">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {user && user.client && (
                <div className="bg-gray-100 p-4 mb-4 rounded-lg shadow">
                  <h2 className="text-xl font-semibold mb-2">Bienvenido, {user.client.name}</h2>
                  <p className="text-gray-600 mb-4">A la izquierda está tu dashboard y abajo tus reportes</p>
                </div>
              )}
              {reports.length === 0 ? (
                <p>No hay reportes.</p>
              ) : (
                reports.map((report) => (
                  <div
                    key={report.id}
                    className="bg-gray-100 p-4 mb-4 rounded-lg shadow cursor-pointer hover:bg-indigo-100"
                    onClick={() => handleReportClick(report.id)}
                  >
                    <h2 className="text-xl font-semibold mb-2">{report.title}</h2>
                    {report.subTitle && <h3 className="text-lg font-medium mb-2">{report.subTitle}</h3>}
                    {report.description && <p className="text-gray-700 mb-4">{report.description}</p>}
                    <p className="text-gray-600">Fecha creación: {formatDate(report.createdAt)}</p>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportList;
