import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import ReportList from './components/ReportList';
import ReportDetail from './components/ReportDetail';
import PrivateRoute from './components/PrivateRoute';
import './axiosConfig'; // Import the Axios configuration

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reports" element={<PrivateRoute Component={ReportList} />} />
        <Route path="/reports/:reportId" element={<PrivateRoute Component={ReportDetail} />} />
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;
