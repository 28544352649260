import axios from 'axios';
import AuthService from './services/AuthService';

// Define the URL for refreshing tokens
const API_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const REFRESH_URL = API_URL + 'token/refresh/';

// Request interceptor to refresh token if necessary
axios.interceptors.request.use(
  async (config) => {
    console.log('Request interceptor triggered');
    console.log(API_URL);
    console.log(REFRESH_URL);
    const user = AuthService.getCurrentUser();

    // Skip interceptor logic for the refresh token request
    if (config.url === REFRESH_URL || config.url === API_URL) {
      return config;
    }

    if (user) {
      const tokenExpiry = JSON.parse(atob(user.access.split('.')[1])).exp * 1000;
      console.log(`Token expiry: ${tokenExpiry}, Current time: ${Date.now()}`);
      if (Date.now() >= tokenExpiry) {
        try {
          console.log('Token expired, refreshing token...');
          const response = await AuthService.refreshToken();
          console.log('Token refreshed');
          AuthService.updateAccessToken(response.data.access);
          config.headers['Authorization'] = `Bearer ${response.data.access}`;
        } catch (error) {
          console.error('Failed to refresh token', error);
          AuthService.logout();
        }
      } else {
        console.log('Token valid, adding to request headers');
        config.headers['Authorization'] = `Bearer ${user.access}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to retry original request with new token
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Skip interceptor logic for the refresh token request
    if (originalRequest.url === REFRESH_URL) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = AuthService.getCurrentUser();
        if (user) {
          console.log('Token expired, retrying with new token...');
          const response = await AuthService.refreshToken();
          AuthService.updateAccessToken(response.data.access);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
          return axios(originalRequest);
        }
      } catch (err) {
        console.error('Failed to refresh token', err);
        AuthService.logout();
      }
    }
    return Promise.reject(error);
  }
);
