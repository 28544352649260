import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_BASE_URL;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + 'token/', { email, password })
      .then(response => {
        if (response.data.access && response.data.refresh) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  async fetchReports(token) {
    return axios.get(API_URL + 'reports/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async fetchReportDetail(token, reportId) {
    return axios.get(`${API_URL}reports/${reportId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async fetchUserProfile(token) {
    return axios.get(API_URL + 'users/profile/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  refreshToken() {
    const user = this.getCurrentUser();
    return axios.post(API_URL + 'token/refresh/', {
      refresh: user.refresh,
    });
  }

  updateAccessToken(newAccessToken) {
    const user = this.getCurrentUser();
    user.access = newAccessToken;
    localStorage.setItem('user', JSON.stringify(user));
  }
}

export default new AuthService();
